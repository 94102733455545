import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './Footer.module.scss';
import i18n from "i18next";

const encryptedMail = btoa("info@cgs-online.de");

class Footer extends React.Component {

  mailTo() {
    window.location.href = "mailto:" + atob(encryptedMail);
  }

  handleHref = (e, postfix) => {
    e.preventDefault();
    let sitePostfix = "de";
    let language = "de";
    if (i18n.language === "en" || i18n.language === "el") {
      sitePostfix = "com";
      language = "en";
      if (postfix === "impressum") {
        postfix = "imprint";
      } else if (postfix === "agb") {
        postfix = "terms";
      } else if (postfix === "datenschutz") {
        postfix = "data-privacy";
      } else if (postfix === "kontakt") {
        postfix = "contact";
      } else {
        postfix = "";
      }
    }
    window.location.href = `https://www.cgs-online.${sitePostfix}/${language}/${postfix}/`
  }

  render() {
    const { t } = this.props;
    return (
        <footer className={styles.Footer} data-testid="Footer">
          <Container className="my-5">
            <Row>
              {/* First Column: Address and Contact */}
              <Col xs={12} md={6} lg={4} className="mb-4 mb-md-0">
                <strong className="d-block" mb={2}>CGS mbH - Consulting Gesellschaft für Systementwicklung</strong>
                <ul className="list-unstyled">
                  <li>Lange Straße 1, 38100 Braunschweig</li>
                  <li><input type="button" className={styles.jsLink} value="info(at)cgs-online.de" onClick={this.mailTo} /></li>
                  <li><a href="tel:+495311209500">+49 (0) 531 / 12 09 - 500</a></li>
                  <li><a href="tel:+495311209599">+49 (0) 531 / 12 09 - 599</a></li>
                </ul>
              </Col>

            <Col xs={12} md={6} lg={4} className="mb-4 mb-md-0">
              <strong className="d-block" mb={2}>CGS Hellas M.I.K.E.</strong>
              <ul className="list-unstyled">
                <li>Vasileos Irakleiou 53 & Karolou Ntil, 54623 Thessaloniki</li>
                <li><input type="button" className={styles.jsLink} value="info(at)cgs-online.gr" onClick={this.mailTo}></input></li>
                <li><a href="tel:+495311209500">+49 (0) 531 / 12 09 - 500</a></li>
                <li><a href="tel:+495311209599">+49 (0) 531 / 12 09 - 599</a></li>
              </ul>
            </Col>
              {/* Social Icons Column */}
              <Col xs={12} md={12} lg={4} className="text-center">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
                <div className="my-4">
                  <a
                      href="https://www.facebook.com/CGS.mbH/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="fa fa-facebook-official"
                      style={{ color: 'blue', fontSize: '3em', marginRight: '20px' }}
                  ></a>

                  <a
                      href="https://www.linkedin.com/products/consulting-gesellschaft-f%C3%BCr-systementwicklung-cgs-mbh-rias/?trk=organization_guest_product_card_related-content-card"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="fa fa-linkedin-square"
                      style={{ color: 'blue', fontSize: '3em' }}
                  ></a>
                </div>
              </Col>
            </Row>
          </Container>

          {/* Footer Links and Copyright Row */}
          <div className={styles.Copyright}>
            <Container>
              <Row className="d-flex align-items-center">
                {/* Copyright on the left */}
                <Col xs={12} md={6} className="text-md-left text-center">
                  <p className="mt-3" style={{ fontSize: '0.875rem', marginBottom: 0 }}>
                    Copyright © 2025  Alle Rechte vorbehalten
                  </p>
                </Col>

                {/* Footer Links on the right */}
                <Col xs={12} md={6} className="text-md-right text-center">
                  <div className="mt-3" style={{ fontSize: '0.875rem', marginBottom: 0 }}>
                    <a href="https://www.cgs-online.de/de/impressum" onClick={(e) => this.handleHref(e,"impressum")} target="_blank" rel="noopener noreferrer">{t("impressum")}</a>
                    &nbsp;|&nbsp;
                    <a href="https://www.cgs-online.de/de/agb" onClick={(e) => this.handleHref(e,"agb")} target="_blank" rel="noopener noreferrer">{t("agb")}</a>
                    &nbsp;|&nbsp;
                    <a href="https://www.cgs-online.de/de/datenschutz" onClick={(e) => this.handleHref(e,"datenschutz")} target="_blank" rel="noopener noreferrer">{t("privacystatement")}</a>
                    &nbsp;|&nbsp;
                    <a href="https://www.cgs-online.de/de/kontakt" onClick={(e) => this.handleHref(e,"kontakt")} target="_blank" rel="noopener noreferrer">{t("contact")}</a>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </footer>
    );
  }
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default withTranslation()(Footer);
